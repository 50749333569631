<template>
  <b-overlay variant="white" :show="error">
    <template class="text-center" slot="overlay">
      Error in data provided
    </template>
    <b-container class="px-0">
      <div class="title mb-5 pb-3">
        <title-info
          condensed
          :info="$t(`page.results.score.${metric}.tooltip`)"
        >
          {{ $t(`page.results.score.${metric}.title`) }}
        </title-info>
      </div>

      <div>
        <b-row no-gutters class="mb-2">
          <b-col
            :key="i"
            v-for="(bar, i) of hidden"
            style="position: relative"
            class="bar mr-2"
            :class="getClass(i)"
          >
            <b-form-input
              :hidden="hidden[i]"
              disabled
              class="slider"
              :value="score"
              :min="getMinMax(i).min"
              :max="getMinMax(i).max"
              ref="range"
              type="range"
            ></b-form-input>
            <div :hidden="hidden[i]" ref="bubble" class="bubble score">
              {{ score }}{{ unit }}
            </div>
            <div
              v-if="jsonVersion >= 6"
              :hidden="normHidden[i]"
              ref="normRef"
              class="norm"
            >
              {{ norm }}{{ unit }}
              <span class="norm-text">{{ $t("page.results.score.norm") }}</span>
            </div>
          </b-col>
        </b-row>

        <b-row no-gutters class="labels" align-h="between">
          <b-col cols="auto">{{ minText }}</b-col>
          <b-col cols="auto">{{ maxText }}</b-col>
        </b-row>

        <b-row class="mb-5" align-h="center">
          <b-col cols="auto">
            <div :style="{ color, borderColor: color }" class="tag">
              {{ scoreText }}
            </div>
          </b-col>
        </b-row>
      </div>

      <div style="font-size: 0.8rem; font-weight: bold">
        {{ $t("page.results.score.important").toUpperCase() }}
      </div>
      <p
        style="font-size: 0.9rem; font-family: Roboto !important; font-weight: 300 !important"
      >
        {{ $t(`page.results.score.${metric}.text`) }}
      </p>
    </b-container>
  </b-overlay>
</template>

<script>
import { mapState } from "vuex";
import TitleInfo from "./TitleInfo.vue";
export default {
  components: { TitleInfo },
  props: {
    metric: {
      type: String,
      required: true
    },
    score: {
      type: [String, Number],
      required: true
    },
    norm: {
      type: [String, Number],
      default: 30
    }
  },
  data() {
    return {
      hidden: [true, true, true],
      normHidden: [true, true, true],
      updatedPosition: 0,
      range: null,
      bubble: null,
      normRef: null
    };
  },

  mounted() {
    this.range = this.$refs.range;
    this.bubble = this.$refs.bubble;
    this.normRef = this.$refs.normRef;

    // TODO: Move this to a handler
    this.reset("hidden");
    if (this.metric === "attentionSpan") {
      if (this.score < 5) {
        this.hidden[0] = false;
      } else if (this.score < 12) {
        this.hidden[1] = false;
      } else {
        this.hidden[2] = false;
      }
    } else {
      if (this.score < 40) {
        this.hidden[0] = false;
      } else if (this.score < 70) {
        this.hidden[1] = false;
      } else {
        this.hidden[2] = false;
      }
    }

    this.bubble.forEach((bubble, index) =>
      this.updateBubble(this.range[index], bubble)
    );

    // TODO: Move this to a handler
    this.reset("normHidden");
    if (this.metric === "attentionSpan") {
      if (this.norm < 5) {
        this.normHidden[0] = false;
      } else if (this.norm < 12) {
        this.normHidden[1] = false;
      } else {
        this.normHidden[2] = false;
      }
    } else {
      if (this.norm < 40) {
        this.normHidden[0] = false;
      } else if (this.norm < 70) {
        this.normHidden[1] = false;
      } else {
        this.normHidden[2] = false;
      }
    }

    if (this.normRef) {
      this.normRef.forEach((norm, index) =>
        this.updateNorm(this.range[index], norm)
      );
    }
  },

  computed: {
    error() {
      if (this.score === null || this.score === undefined) {
        return true;
      }
      return false;
    },

    ...mapState("analysis", ["jsonVersion"]),

    unit() {
      return this.metric === "attentionSpan" ? "s" : "%";
    },
    scoreText() {
      if (this.metric === "attentionSpan") {
        if (this.score < 5) {
          return this.$t(
            `page.results.score.scoreBar.actionNeeded`
          ).toUpperCase();
        } else if (this.score < 12) {
          return this.$t(
            `page.results.score.scoreBar.workToBeDone`
          ).toUpperCase();
        } else {
          return this.$t(`page.results.score.scoreBar.onTrack`).toUpperCase();
        }
      } else {
        if (this.score < 40) {
          return this.$t(
            `page.results.score.scoreBar.actionNeeded`
          ).toUpperCase();
        } else if (this.score < 70) {
          return this.$t(
            `page.results.score.scoreBar.workToBeDone`
          ).toUpperCase();
        } else {
          return this.$t(`page.results.score.scoreBar.onTrack`).toUpperCase();
        }
      }
    },

    color() {
      if (this.metric === "attentionSpan") {
        if (this.score < 5) {
          return "red";
        } else if (this.score < 12) {
          return "var(--orange)";
        } else {
          return "var(--green)";
        }
      } else {
        if (this.score < 40) {
          return "red";
        } else if (this.score < 70) {
          return "var(--orange)";
        } else {
          return "var(--green)";
        }
      }
    },

    minText() {
      if (this.metric === "predictiveClick") {
        return this.$t("page.results.score.lowClicks");
      } else {
        return this.$t(`page.results.score.low`);
      }
    },

    maxText() {
      if (this.metric === "predictiveClick") {
        return this.$t("page.results.score.highClicks");
      } else {
        return this.$t(`page.results.score.high`);
      }
    }
  },
  watch: {
    score: {
      handler(value) {
        this.reset("hidden");

        if (this.metric === "attentionSpan") {
          if (value < 5) {
            this.hidden[0] = false;
          } else if (value < 12) {
            this.hidden[1] = false;
          } else {
            this.hidden[2] = false;
          }
        } else {
          if (value < 40) {
            this.hidden[0] = false;
          } else if (value < 70) {
            this.hidden[1] = false;
          } else {
            this.hidden[2] = false;
          }
        }

        this.bubble.forEach((bubble, index) =>
          this.updateBubble(this.range[index], bubble)
        );
      }
    },

    norm: {
      handler(value) {
        this.reset("normHidden");

        if (this.metric === "attentionSpan") {
          if (value < 5) {
            this.normHidden[0] = false;
          } else if (value < 12) {
            this.normHidden[1] = false;
          } else {
            this.normHidden[2] = false;
          }
        } else {
          if (value < 40) {
            this.normHidden[0] = false;
          } else if (value < 70) {
            this.normHidden[1] = false;
          } else {
            this.normHidden[2] = false;
          }
        }

        if (this.normRef) {
          this.normRef.forEach((norm, index) =>
            this.updateNorm(this.range[index], norm)
          );
        }
      }
    }
  },

  methods: {
    reset(array) {
      if (array === "normHidden") {
        this.normHidden = [true, true, true];
      } else if (array === "hidden") {
        this.hidden = [true, true, true];
      }
    },

    getClass(index) {
      if (!this.hidden[index]) {
        if (index === 0) {
          return "bg-red-custom";
        } else if (index === 1) {
          return "bg-orange";
        } else if (index === 2) {
          return "bg-green";
        }
      }
    },

    getMinMax(index) {
      let min = 0;
      let max = 0;

      if (this.metric === "attentionSpan") {
        if (index === 0) {
          min = 0;
          max = 4.99;
        } else if (index === 1) {
          min = 5;
          max = 11.99;
        } else {
          min = 12;
          max = 20;
        }
      } else {
        if (index === 0) {
          min = 0;
          max = 39;
        } else if (index === 1) {
          min = 40;
          max = 69;
        } else {
          min = 70;
          max = 100;
        }
      }

      return { min, max };
    },

    updateBubble(range, bubble) {
      let val = this.score;
      const min = range.min ? range.min : 0;
      const max = range.max ? range.max : 100;

      if (this.metric === "attentionSpan") {
        if (val > 20) {
          val = 20;
        }
      } else {
        if (val > 100) {
          val = 100;
        }
      }

      if (val < 0) {
        val = 0;
      }

      const newVal = Number(((val - min) * 100) / (max - min));

      bubble.style.left = `calc(${newVal}% + (${20 - newVal * 0.15}px))`;
    },

    updateNorm(range, normRef) {
      let val = this.norm;
      const min = range.min ? range.min : 0;
      const max = range.max ? range.max : 100;

      if (this.metric === "attentionSpan") {
        if (val > 20) {
          val = 20;
        }
      } else {
        if (val > 100) {
          val = 100;
        }
      }

      if (val < 0) {
        val = 0;
      }

      const newVal = Number(((val - min) * 100) / (max - min));

      normRef.style.left = `calc(${newVal}% + (${20 - newVal * 0.15}px))`;
    }
  }
};
</script>

<style scoped>
.labels {
  margin-bottom: 5rem;
}
.bar {
  width: 100%;
  height: 7px;
  background: var(--gray-light);
}
.score {
  text-align: center;
  font-size: 2.5rem;
  font-weight: 500;
}

.norm {
  position: relative;
  font-size: 1.2rem;
  color: var(--gray-dark);
  font-weight: 500;
  padding: 4px 12px;
  position: absolute;
  border-radius: 4px;
  left: 0%;
  line-height: 1rem;
  top: 3.5rem;
  transform: translateX(-60%);
}

.norm:before {
  content: "";
  position: absolute;
  transform: translate(62%, -60px);
  width: 20px;
  height: 55px;
  border-left: 1px dashed var(--gray-dark);
}

.norm-text {
  display: block;
  position: relative;
  left: -3px;
  font-size: 0.8rem;
}

.bg-red-custom {
  background: red;
}

.slider {
  position: absolute;
  z-index: 2;
  height: 7px;
  -webkit-appearance: none; /* Override default CSS styles */
  appearance: none;
  width: 100%; /* Full-width */
  outline: none; /* Remove outline */
  opacity: 1; /* Set transparency (for mouse-over effects on hover) */
}

.slider::-webkit-slider-runnable-track {
  background-color: transparent !important;
}
.slider::-moz-range-track {
  background-color: transparent !important;
}

/* .slider::-webkit-slider-thumb::before,
.slider::-moz-range-track::before, ,
.slider::-ms-thumb {
  content: "10";
  top: 10px;
} */
.slider::-webkit-slider-thumb {
  width: 16px;
  height: 16px;
  -webkit-appearance: none;
  appearance: none;
  border: 1px black solid;
  background: white;
}

.slider::-moz-range-thumb {
  width: 16px;
  height: 16px;
  -webkit-appearance: none;
  appearance: none;
  border: 1px black solid;
  background: white;
}

.slider::-ms-track {
  -webkit-appearance: none;
  appearance: none;
  background: white;
}
.tag {
  border: 1px solid var(--orange);
  padding: 2px 15px;
  font-weight: bold;
  color: var(--orange);
}

.bubble {
  padding: 4px 12px;
  position: absolute;
  border-radius: 4px;
  left: 0%;
  top: -60px;
  transform: translateX(-50%);
}
/* .bubble::after {
  content: "";
  position: absolute;
  width: 2px;
  height: 2px;
  background: red;
  top: 1px;
  left: 50%;
} */
</style>

<style>
.tooltip-inner {
  padding: 15px;
  text-align: left;
}
</style>
