<template>
  <div>
    <b-container fluid class="px-0">
      <b-row>
        <b-col cols="12" sm="6" class="border-right border-1">
          <title-info condensed> {{ $t("gender") }}</title-info>
          <div class="value">{{ video.gender.value }}%</div>
          <div
            :style="{ color: getGenderColor(video.gender.label) }"
            class="label text-uppercases"
          >
            {{ $t(video.gender.label.toLowerCase()) }}
          </div>
        </b-col>
        <b-col cols="12" sm="6" class="pl-4">
          <title-info condensed> {{ $t("age") }}</title-info>
          <div class="value">{{ video.age.value }}%</div>
          <div :style="{ color: getAgeColor(video.age.label) }" class="label">
            {{ video.age.label.toUpperCase() }}
          </div>
        </b-col>
      </b-row>
    </b-container>

    <div class="why-is-important">
      <div class="why-is-important--title">
        {{ $t("page.results.score.important").toUpperCase() }}
      </div>
      <p class="why-is-important--text">
        {{ $t("page.results.score.mediaOptimization.text") }}
      </p>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import TitleInfo from "./TitleInfo.vue";
export default {
  components: { TitleInfo },

  computed: {
    ...mapState("analysis", ["videoData", "videoIndex"]),

    video() {
      return this.videoData[this.videoIndex];
    }
  },

  methods: {
    getGenderColor(gender) {
      const t = gender.toLowerCase();
      if (t === "female") {
        return "red";
      } else {
        return "rgba(61, 174, 255)";
      }
    },

    getAgeColor(age) {
      const t = age.toLowerCase();

      if (t === "under 34") {
        return "var(--green)";
      } else {
        return "rgba(255, 124, 38)";
      }
    }
  }
};
</script>

<style scoped>
.value {
  font-weight: bold;
  font-size: 2.5rem;
}

.label {
  white-space: nowrap;
  font-size: 2rem;
  font-weight: bolder;
}

.why-is-important {
  margin-top: 9.2rem;
}

.why-is-important--title {
  font-size: 0.8rem !important;
  font-weight: bold !important;
}

.why-is-important--text {
  font-size: 0.9rem;
  font-family: Roboto !important;
  font-weight: 300 !important;
}

@media screen and (min-width: 992px) and (max-width: 1320px) {
  .label {
    font-size: 1.5rem;
  }
}

@media screen and (min-width: 769px) and (max-width: 830px) {
  .label {
    font-size: 1.5rem;
  }
}
</style>
