<template>
  <div class="gauge"></div>
</template>

<script>
import * as am4core from "@amcharts/amcharts4/core";
import * as am4charts from "@amcharts/amcharts4/charts";

import am4themesAnimated from "@amcharts/amcharts4/themes/animated";

am4core.useTheme(am4themesAnimated);
am4core.options.autoSetClassName = true;

export default {
  props: ["valence", "globalPerformace", "norm"],
  data() {
    return {
      chart: null,
      label: null,
      label2: null
    };
  },

  mounted() {
    this.$nextTick(() => {
      this.initialize();
    });
  },

  computed: {
    positiveLabel() {
      return this.$t("page.results.emotionAnalyticsLabels.positive");
    },

    negativeLabel() {
      return this.$t("page.results.emotionAnalyticsLabels.negative");
    }
  },

  watch: {
    positiveLabel: {
      handler: function() {
        if (this.chart) this.chart.dispose();
        this.initialize();
      }
    },

    negativeLabel: {
      handler: function() {
        if (this.chart) this.chart.dispose();
        this.initialize();
      }
    },

    valence: {
      handler: function() {
        if (this.chart) this.chart.dispose();
        this.initialize();
      }
    },

    globalPerformace: {
      handler: function() {
        if (this.globalPerformace) {
          this.label.text = `${this.globalPerformace}%`;
        }
      }
    },

    norm: {
      handler: function() {
        if (this.chart) this.chart.dispose();
        this.initialize();
        this.label2.text = `${this.$t("page.results.score.norm")}: ${
          this.norm
        }%`;
      }
    }
  },

  methods: {
    initialize() {
      this.createChart();
      const negativeAxis = this.createAxis(
        0,
        100,
        2.5,
        177.5,
        this.getAxisColor("negative")
      );
      const positiveAxis = this.createAxis(
        0,
        100,
        182.5,
        357.5,
        this.getAxisColor("positive")
      );

      this.createLabel(negativeAxis, 50, this.negativeLabel);
      this.createLabel(positiveAxis, 50, this.positiveLabel);
    },

    createChart() {
      this.chart = am4core.create("gauge", am4charts.GaugeChart);
      this.chart.startAngle = 0;
      this.chart.endAngle = 360;
      this.chart.innerRadius = 0;
      this.chart.width = am4core.percent(100);
      this.chart.height = am4core.percent(100);
      this.chart.radius = am4core.percent(80);
      this.chart.chartContainer.align = "center";
      this.chart.chartContainer.valign = "middle";

      const labelContainer = this.chart.chartContainer.createChild(
        am4core.Container
      );
      labelContainer.isMeasured = false;
      labelContainer.width = am4core.percent(100);
      labelContainer.height = am4core.percent(100);
      labelContainer.align = "center";
      labelContainer.valign = "bottom";
      labelContainer.layout = "horizontal";

      const labelContainer2 = labelContainer.createChild(am4core.Container);
      labelContainer2.width = am4core.percent(100);
      labelContainer2.valign = "middle";
      labelContainer2.layout = "vertical";

      this.label = labelContainer2.createChild(am4core.Label);
      this.label.align = "center";
      this.label.text = "80%";
      this.label.fontSize = "4.5rem";
      this.label.paddingBottom = -15;
      this.label.fontWeight = "700";
      this.label.fill = am4core.color("#212529");
      this.label.text = this.globalPerformace + "%";

      this.label2 = labelContainer2.createChild(am4core.Label);
      this.label2.align = "center";
      this.label2.valign = "middle";
      this.label2.text = "70%";
      this.label2.fontSize = "1.1rem";
      this.label2.fontWeight = "500";
      this.label2.text = `${this.$t("page.results.score.norm")}: ${this.norm}%`;
      this.label2.fill = am4core.color("grey");

      this.chart.chartContainer.events.on("sizechanged", () => {
        this.label.scale =
          this.chart.chartContainer.bbox.height / (this.label.bbox.width * 3);
        this.label2.scale =
          this.chart.chartContainer.bbox.height / (this.label2.bbox.width * 3);
      });

      // labelContainer.events.on("sizechanged", () => {
      //   label.scale = labelContainer2.bbox.width / (label.bbox.width * 3);
      //   label2.scale = labelContainer2.bbox.width / (label.bbox.width * 3);
      // });
    },

    createAxis(min, max, start, end, color) {
      const axis = this.chart.xAxes.push(new am4charts.ValueAxis());
      axis.min = min;
      axis.max = max;
      axis.strictMinMax = true;
      axis.renderer.useChartAngles = false;
      axis.renderer.startAngle = start;
      axis.renderer.endAngle = end;
      axis.renderer.minGridDistance = 100;

      axis.renderer.line.strokeOpacity = 1;
      axis.renderer.line.strokeWidth = 14;
      axis.renderer.line.stroke = am4core.color(color);

      // axis.renderer.ticks.template.disabled = true;
      axis.renderer.grid.template.disabled = true;
      axis.renderer.grid.template.strokeWidth = 0;
      axis.renderer.labels.template.disabled = true;

      return axis;
    },

    createLabel(axis, value, text) {
      const axisRanges = axis.axisRanges.create();
      axisRanges.value = value;
      axisRanges.label.text = text;
      axisRanges.label.fontSize = 15;

      this.chart.chartContainer.events.on("sizechanged", () => {
        axisRanges.label.scale =
          this.chart.chartContainer.bbox.height /
          ((axisRanges.label.bbox.width - 0.0001) * 4);
      });
    },

    getAxisColor(axis) {
      if (this.valence === "negative" && axis === "negative") {
        return "red";
      }
      if (this.valence === "positive" && axis === "positive") {
        return "green";
      }
      return "#e9ecef";
    }
  },

  beforeDestroy() {
    if (this.chart) {
      this.chart.dispose();
    }
  }
};
</script>

<style scoped>
.gauge {
  width: 100%;
  height: 65%;
  min-width: 200px;
  min-height: 200px;
}
</style>
